.open__newtab {
  cursor: pointer !important;
}

/* 23/1/2023 HIDE FOR KIM-YOUNGDAE RELEASE */
/* .bgLR {
  position: relative;
  width: 80vw;
  margin: 0 auto; 
  cursor: pointer;
} */
/* .bgLR span.mask {
  position: relative;
  display: block;
  line-height: 0;
  overflow: hidden; 
} */
/* .bgLR span.mask::before {
  content: "";
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  opacity: 0; 
  transition: 0.3s ease-in-out; 
  transform: translateX(-100%);
  background-image: linear-gradient(
    to right,
    #ff8177 0%,
    #ff867a 0%,
    #ff8c7f 21%,
    #f99185 52%,
    #cf556c 78%,
    #b12a5b 100%
  );
  width: 100%;
  height: 100%;
}

.bgLR:hover span.mask::before {
  opacity: 0.5;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
}

.bgLR span.cap {
  position: absolute;
  opacity: 0;
  transition: 0.5s ease-in-out;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  line-height: 1.5;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.bgLR:hover span.cap {
  opacity: 1;
} */

.mySwiper {
  padding: 30px 0 !important;
  position: relative;
}
.mySwiper .swiper-pagination {
  width: 200px !important;
  margin-left: 30px;
  position: initial !important;
  margin-top: 15px;
}
.mySwiper .swiper-pagination-progressbar-fill {
  background-color: black !important;
}
.bannerSlideNumber {
  margin-left: 40px;
  margin-top: -25px;
  font-family: 'futura-pt';
}
@media (min-width: 600px) {
  .mySwiper .swiper-pagination {
    margin-left: 80px;
  }
  .bannerSlideNumber {
    margin-left: 90px;
  }
}
@media (min-width: 1404px) {
  .mySwiper .swiper-pagination {
    margin-left: 200px;
  }
  .bannerSlideNumber {
    margin-left: 210px;
  }
}
.bottomBanner {
  padding: 50px 0 !important;
}

.main__slide {
  height: 100vh;
}

@media screen and (max-width: 767px) {
  .main__slide {
    height: 80vh;
  }
}
