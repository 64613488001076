.fc{
  font-family: 'futura-pt';
  font-weight: 700 !important;
  background-color: #fff;
}
.fc .fc-scrollgrid-section-body{
  background-color: #ffffff;
}
.fc .fc-scrollgrid-section-header{
  padding: 10px 0;
}
.fc .fc-day-other{
  background-color: #f1f1f1;
}
.fc .fc-day-sat{
  color: #5270C3;
}
.fc .fc-day-sun{
  color: #CD5757;
}
.fc .fc-col-header{
  margin: 10px 0;
}
.fc .fc-daygrid-day-top{
  flex-direction: row !important;
}
.fc .fc-daygrid-day-number{
  font-weight: 800 !important;
}
.fc .fc-hol .fc-daygrid-day-top{
  color: #CD5757;
  border: 2px solid #CD5757;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}