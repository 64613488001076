.i-mail:after,
.i-mail .mail-anim:after {
  content: '';
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: 15px;
  height: 4px;
  border-bottom: 2px solid black;
  border-top: 2px solid black;
}

.i-mail:before,
.i-mail .mail-anim:before {
  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  width: 7px;
  height: 6px;
  background: black;
}

.animation {
  width: 100%;
  max-width: 540px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

.i-mail,
.i-mail .mail-anim {
  width: 50px;
  height: 40px;
  border-radius: 5% 5%;
  border: 2px solid black;
  position: relative;
  animation: transformS 2s linear;
  -webkit-animation: transformS 2s linear;
}
@keyframes transformS {
  50% {
    transform: scale(0.5, 0.5);
    opacity: 0.5;
  }
}
.i-mail .mail-anim {
  margin: -2px 0 0 -2px;
  animation: moveL 2s linear;
  -webkit-animation: moveL 2s linear;
}

@keyframes moveL {
  50% {
    transform: translateX(80px) translateY(-30px);
    -webkit-transform: translateX(80px) translateY(-30px);
    -moz-transform: translateX(80px) translateY(-30px);
    -ms-transform: translateX(80px) translateY(-30px);
    -o-transform: translateX(80px) translateY(-30px);
  }
  60% {
    transform: translateX(100px) translateY(-30px);
    -webkit-transform: translateX(100px) translateY(-30px);
    -moz-transform: translateX(100px) translateY(-30px);
    -ms-transform: translateX(100px) translateY(-30px);
    -o-transform: translateX(100px) translateY(-30px);
  }
  100% {
    transform: translateX(300px) rotateY(90deg);
    -webkit-transform: translateX(300px) rotateY(90deg);
    -moz-transform: translateX(300px) rotateY(90deg);
    -ms-transform: translateX(300px) rotateY(90deg);
    -o-transform: translateX(300px) rotateY(90deg);
  }
}

.line {
  padding: 1px 30%;
  background-image: linear-gradient(
    to right,
    #000 30%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: top;
  background-size: 15px 2px;
  background-repeat: repeat-x;
}

/* Mail success */
.i-success {
  background-color: pink;
  display: inline-block;
  height: 30px;
  margin: 0 10px;
  position: relative;
  top: 0;
  transform: rotate(-45deg);
  width: 30px;
  animation: transformB 0.3s 2s linear forwards;
  -webkit-animation: transformB 0.3s 2s linear forwards;
}

.i-success:after {
  content: '';
  background-color: pink;
  border-radius: 50%;
  height: 30px;
  position: absolute;
  width: 30px;
}

.i-success:before {
  content: '';
  background-color: pink;
  border-radius: 50%;
  height: 30px;
  position: absolute;
  width: 30px;
}

.i-success:before {
  top: -15px;
  left: 0;
}

.i-success:after {
  left: 15px;
  top: 0;
}

@keyframes transformB {
  50% {
    transform: scale(1.5, 1.5) rotate(-45deg);
    -webkit-transform: scale(1.5, 1.5) rotate(-45deg);
    -moz-transform: scale(1.5, 1.5) rotate(-45deg);
    -ms-transform: scale(1.5, 1.5) rotate(-45deg);
    -o-transform: scale(1.5, 1.5) rotate(-45deg);
  }
  100% {
    transform: rotate(-45deg);
  }
}
@keyframes transformBA {
  100% {
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
  }
}

.success-anim {
  margin: -2px 0 0 -2px;
  animation: moveR 0.8s 2s linear;
  -webkit-animation: moveR 0.8s 2s linear;
}
@keyframes moveR {
  0% {
    transform: translateX(-220px) rotateY(90deg);
  }
  50% {
    transform: translateX(0) rotateY(0);
  }
}
